import withHeader from "hocs/withHeader";
import Message from "semantic-ui-react/dist/commonjs/collections/Message";
import withFooter from "hocs/withFooter";
import { useContext } from "react";
import { UserProfileContext } from "@context";

const Custom404Page = () => {
	const { user } = useContext(UserProfileContext);

	const ErrorJSX = () => {
		return (
			<div className="padding-5-x padding-4-y text-centered">
				<Message
					warning
					size={"big"}
				>
					<Message.Header>Página no encontrada</Message.Header>
					<p>
						La página que usted está buscando no se encuentra. Podría ser que se haya removido, renombrado, o solamente
						que no existe.
					</p>
				</Message>
			</div>
		);
	};

	const ErrorJSXWithFooter = withFooter(ErrorJSX);

	return withHeader(ErrorJSXWithFooter)({ user });
};

export default Custom404Page;
